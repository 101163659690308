<template>
    <div class="mypage_item_history_wrap">
        <!--        마이 아이템 임시저장 레이아웃-->
        <table class="my_history_table" width="100%" border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="90px" />
                <col width="135px" />
                <col width="190px" />
                <col width="130px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{ $t('image') }}</th>
                <th colspan="1">{{ $t('subject') }}</th>
                <th colspan="1">{{ $t('status') }}</th>
            </tr>
            <tr v-for="(data,index) in itemData" :key="`item${index}`" class="move_detail_btn" @click="movePage(data.i_sku)">
                <td>{{tableIdx(index)}}</td>  <!-- No -->
                <td><img :src="returnThumbnail(data)" width="78" height="49"/></td>
                <td class="t_subject">{{ data.i_name }}</td>  <!-- 아이템명 -->

                <td>{{ $t('unexposed') }}</td>  <!-- 상태 -->

            </tr>
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
export default {
    name: "ItemListMyTempSaveLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`/item/update/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'png')}`;
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
